import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ViewOffCanvance.css";
import { Context } from "../../utils/context";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "react-phone-input-2/lib/bootstrap.css";
import Select from "react-select";
import { CancelButton, SaveButton } from "../common/Button";
import ModalSave from "../common/ModelSave";

library.add(fas);

const ViewOffCanvance = (props) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const dataToSend = new FormData();
      dataToSend.append("stage", data?.stage?.value);
      dataToSend.append("email", data?.email);
      const response = await postData(
        `/employee/seller/request/${props.id}`,
        dataToSend
      );

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [data, setData] = useState();

  const getSellerData = async () => {
    const res = await getData(`/finance-product-report/${props.id}`);
    setData(res.data?.data);
  };

  useEffect(() => {
    getSellerData();
  }, [props.id]);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={props.handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Product Details </h3>
                </Card.Title>

                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="About Product" value="1" />
                        <Tab label="Seller Details" value="2" />
                        <Tab label="Product Details" value="3" />
                        <Tab label="Product Cost To Boomerbucks" value="4" />
                        <Tab label="Product Cost On Website" value="5" />
                        <Tab label="Product Images" value="6" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div>
                        <div>
                          <h3>About Product</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Product Id</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.id}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Product Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Short Description</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.short_description}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>{" "}
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Product Varient</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.type}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Membership</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.membership_type
                                            ? data?.membership_type
                                            : "No Membership"
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Membership Discount </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.membership_discount
                                            ? data?.membership_discount
                                            : "No Membership Discount"
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>COD Available </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.cod_available ? "YES" : "NO"
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Category</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.category?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>{" "}
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Sub Category</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.sub_category?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Commission</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.commission}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>{" "}
                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Product Image</Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={IMG_URL + data?.image1}
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div>
                        <div>
                          <h3>Seller Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.user?.first_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.user?.last_name || "-"}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>{" "}
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.user?.email}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>{" "}
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Contact Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.user?.contact_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Service Start Date</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.user?.commission_s_date
                                            ? new Date(
                                                data.user.commission_s_date
                                              ).toLocaleDateString("en-GB", {
                                                day: "numeric",
                                                month: "short",
                                                year: "numeric",
                                              })
                                            : ""
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Service End Date</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.user?.commission_e_date
                                            ? new Date(
                                                data.user.commission_e_date
                                              ).toLocaleDateString("en-GB", {
                                                day: "numeric",
                                                month: "short",
                                                year: "numeric",
                                              })
                                            : ""
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div>
                        <Row>
                          <Col lg={4}>
                            <Form.Label>Product Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={data?.name}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>{" "}
                          <Col lg={4}>
                            <Form.Label>Manufacturer</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={data?.product_detail?.manufacturer}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>{" "}
                          <Col lg={4}>
                            <Form.Label>Brand</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={data?.product_detail?.brand?.name}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>{" "}
                          <Col lg={4}>
                            <Form.Label>Tax Type</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={data?.product_detail?.tax_type?.name}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>{" "}
                          <Col lg={4}>
                            <Form.Label>Tax Percentage</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={
                                    data?.product_detail?.tax_percentage?.name
                                  }
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>{" "}
                          <Col lg={4}>
                            <Form.Label>Vendor</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  value={data?.product_detail?.vender}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        <br />

                        <hr></hr>
                        {data?.type == "Single" ? (
                          <div>
                            <div>
                              <h4>Single Varient</h4>
                            </div>
                            <Row>
                              {/* <Col lg={4}> */}

                              <div className="main-form-section mt-4">
                                {data?.product_variants.map((value) => {
                                  return (
                                    <>
                                      <Row className="justify-content-center">
                                        <Col lg={4}>
                                          <Form.Label>Variant Name</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={value?.v_name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                          <Form.Label>Stock Unit</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={value?.unit}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                          <Form.Label>MRP</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={value?.mrp}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                          <Form.Label>SKU</Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={value?.sku}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>{" "}
                                        <Col lg={4}>
                                          <Form.Label>
                                            Weight Or Piece
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={value?.weight_or_piece}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                        {value?.weight_or_piece == "piece" ? (
                                          <Col lg={4}>
                                            <Form.Label>Piece</Form.Label>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  value={value?.piece}
                                                  disabled
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                        ) : (
                                          <Col lg={4}>
                                            <Form.Label>Weight</Form.Label>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  value={value?.weight}
                                                  disabled
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                        )}
                                      </Row>
                                    </>
                                  );
                                })}
                              </div>

                              {/* </Col> */}
                            </Row>
                          </div>
                        ) : (
                          <div>
                            {" "}
                            <div>
                              <h4>Multiple Varient</h4>
                            </div>
                            <Row>
                              {data?.product_multiple_mains.map((value) => {
                                return (
                                  <Col lg={12} key={value?.id}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Col lg={4}>
                                          <Form.Label>
                                            Multiple Main Variant
                                          </Form.Label>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                value={value?.varient_name}
                                                disabled
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>

                                    {value?.product_multiple_variants?.map(
                                      (value2) => {
                                        return (
                                          <>
                                            {/* <Col lg={4} key={value2?.id}> */}
                                            <div className="main-form-section mt-4">
                                              <Row className="justify-content-center">
                                                <Col lg={4}>
                                                  <Form.Label>
                                                    Variant Sub Name
                                                  </Form.Label>
                                                  <Form.Group>
                                                    <InputGroup>
                                                      <Form.Control
                                                        type="text"
                                                        value={
                                                          value2?.varient_sub_name
                                                        }
                                                        disabled
                                                      />
                                                    </InputGroup>
                                                  </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                  <Row className="justify-content-center">
                                                    <Form.Label>
                                                      Unit
                                                    </Form.Label>
                                                    <Form.Group>
                                                      <InputGroup>
                                                        <Form.Control
                                                          type="text"
                                                          value={value2?.unit}
                                                          disabled
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </Row>
                                                </Col>{" "}
                                                <Col lg={4}>
                                                  <Row className="justify-content-center">
                                                    <Form.Label>MRP</Form.Label>
                                                    <Form.Group>
                                                      <InputGroup>
                                                        <Form.Control
                                                          type="text"
                                                          value={value2?.mrp}
                                                          disabled
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </Row>
                                                </Col>
                                                <Col lg={4}>
                                                  <Row className="justify-content-center">
                                                    <Form.Label>SKU</Form.Label>
                                                    <Form.Group>
                                                      <InputGroup>
                                                        <Form.Control
                                                          type="text"
                                                          value={value2?.sku}
                                                          disabled
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </Row>
                                                </Col>
                                                <Col lg={4}>
                                                  <Row className="justify-content-center">
                                                    <Form.Label>
                                                      Weight Or Piece
                                                    </Form.Label>
                                                    <Form.Group>
                                                      <InputGroup>
                                                        <Form.Control
                                                          type="text"
                                                          value={
                                                            value2?.weight_or_piece
                                                          }
                                                          disabled
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </Row>
                                                </Col>
                                                {value2?.weight_or_piece ==
                                                "piece" ? (
                                                  <Col lg={4}>
                                                    <Row className="justify-content-center">
                                                      <Form.Label>
                                                        Piece
                                                      </Form.Label>
                                                      <Form.Group>
                                                        <InputGroup>
                                                          <Form.Control
                                                            type="text"
                                                            value={
                                                              value2?.piece
                                                            }
                                                            disabled
                                                          />
                                                        </InputGroup>
                                                      </Form.Group>
                                                    </Row>
                                                  </Col>
                                                ) : (
                                                  <Col lg={4}>
                                                    <Row className="justify-content-center">
                                                      <Form.Label>
                                                        Weight
                                                      </Form.Label>
                                                      <Form.Group>
                                                        <InputGroup>
                                                          <Form.Control
                                                            type="text"
                                                            value={
                                                              value2?.weight
                                                            }
                                                            disabled
                                                          />
                                                        </InputGroup>
                                                      </Form.Group>
                                                    </Row>
                                                  </Col>
                                                )}
                                                <hr></hr>
                                              </Row>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel value="4">
                      <div>
                        <div>
                          <h3>Cost to Boomerbucks</h3>
                        </div>

                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Product Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>{" "}
                            {data?.product_variants?.map((value) => (
                              <>
                                {" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>MRP</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.mrp}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>{" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>Trade Percent</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.trade}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>{" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>Scheme Percent</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.scheme}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>{" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>
                                        Additional Discount
                                      </Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.additional_discount}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>{" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>
                                        Cost To Boomerbucks
                                      </Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.cost_to_boomerbucks}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>
                              </>
                            ))}
                            {data?.product_multiple_mains?.map((value) => (
                              <>
                                {" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>
                                        Product Main Varient Name
                                      </Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.varient_name}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>
                                <hr></hr>
                                {value?.product_multiple_variants?.map(
                                  (val) => (
                                    <><Row>
                                      <Col md={4}>
                                        <div className="main-form-section mt-4">
                                          <Row className="justify-content-center">
                                            <Form.Label>
                                              Varient Name
                                            </Form.Label>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  value={val?.varient_sub_name}
                                                  disabled
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="main-form-section mt-4">
                                          <Row className="justify-content-center">
                                            <Form.Label>MRP</Form.Label>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  value={val?.mrp}
                                                  disabled
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="main-form-section mt-4">
                                          <Row className="justify-content-center">
                                            <Form.Label>
                                              Trade Percent
                                            </Form.Label>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  value={val?.trade}
                                                  disabled
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="main-form-section mt-4">
                                          <Row className="justify-content-center">
                                            <Form.Label>
                                              Scheme Percent
                                            </Form.Label>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  value={val?.scheme}
                                                  disabled
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      </Col>{" "}
                                      <Col md={4}>
                                        <div className="main-form-section mt-4">
                                          <Row className="justify-content-center">
                                            <Form.Label>
                                              Additional Discount
                                            </Form.Label>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  value={
                                                    val?.additional_discount
                                                  }
                                                  disabled
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      </Col>{" "}
                                      <Col md={4}>
                                        <div className="main-form-section mt-4">
                                          <Row className="justify-content-center">
                                            <Form.Label>
                                              Cost To Boomerbucks
                                            </Form.Label>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  value={
                                                    val?.cost_to_boomerbucks
                                                  }
                                                  disabled
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      </Col></Row><hr></hr>
                                    </>
                                  )
                                )}
                              </>
                            ))}
                          </Row>
                          <br></br>
                          <hr></hr>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="5">
                      <div>
                        <div>
                          <h3>Cost On Website</h3>
                        </div>

                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Product Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>{" "}
                            {data?.product_variants?.map((value) => (
                              <>
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>Trade Percent</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.trade}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>{" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>MRP</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.mrp}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>{" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>Discount</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.discount}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>
                                {value?.discount == "rupees" ? (
                                  <Col md={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>Rupees</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={value?.rupees}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>
                                ) : (
                                  <Col md={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>Percent</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={value?.percent}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>
                                )}{" "}
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>Selling Price</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.s_price}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>
                              </>
                            ))}
                            {data?.product_multiple_mains?.map((value) => (
                              <>
                                <Col md={4}>
                                  <div className="main-form-section mt-4">
                                    <Row className="justify-content-center">
                                      <Form.Label>Varient Name</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            value={value?.varient_name}
                                            disabled
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>
                                <hr></hr>
                                {value?.product_multiple_variants?.map(
                                  (val) => (
                                    <>
                                      <Row>
                                        <Col md={4}>
                                          <div className="main-form-section mt-4">
                                            <Row className="justify-content-center">
                                              <Form.Label>MRP</Form.Label>
                                              <Form.Group>
                                                <InputGroup>
                                                  <Form.Control
                                                    type="text"
                                                    value={val?.mrp}
                                                    disabled
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </Row>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div className="main-form-section mt-4">
                                            <Row className="justify-content-center">
                                              <Form.Label>Discount</Form.Label>
                                              <Form.Group>
                                                <InputGroup>
                                                  <Form.Control
                                                    type="text"
                                                    value={val?.discount}
                                                    disabled
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </Row>
                                          </div>
                                        </Col>
                                        {val?.discount == "rupees" ? (
                                          <Col md={4}>
                                            <div className="main-form-section mt-4">
                                              <Row className="justify-content-center">
                                                <Form.Label>Rupees</Form.Label>
                                                <Form.Group>
                                                  <InputGroup>
                                                    <Form.Control
                                                      type="text"
                                                      value={val?.rupees}
                                                      disabled
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </Row>
                                            </div>
                                          </Col>
                                        ) : (
                                          <Col md={4}>
                                            <div className="main-form-section mt-4">
                                              <Row className="justify-content-center">
                                                <Form.Label>Percent</Form.Label>
                                                <Form.Group>
                                                  <InputGroup>
                                                    <Form.Control
                                                      type="text"
                                                      value={val?.percent}
                                                      disabled
                                                    />
                                                  </InputGroup>
                                                </Form.Group>
                                              </Row>
                                            </div>
                                          </Col>
                                        )}{" "}
                                        <Col md={4}>
                                          <div className="main-form-section mt-4">
                                            <Row className="justify-content-center">
                                              <Form.Label>
                                                Selling Price
                                              </Form.Label>
                                              <Form.Group>
                                                <InputGroup>
                                                  <Form.Control
                                                    type="text"
                                                    value={val?.s_price}
                                                    disabled
                                                  />
                                                </InputGroup>
                                              </Form.Group>
                                            </Row>
                                          </div>
                                        </Col>
                                      </Row><hr></hr>
                                    </>
                                  )
                                )}
                              </>
                            ))}
                          </Row>
                          <br></br>
                          <hr></hr>
                        </div>
                      </div>
                    </TabPanel>{" "}
                    <TabPanel value="6">
                      <div>
                        <div>
                          <h3>Product Images</h3>
                        </div>

                        <div>
                          <Row>
                            {data?.type === "Single" ? (
                              <>
                                {data?.product_variants?.map((image) =>
                                  image?.product_images?.map((value) => (
                                    <Col md={4} key={value?.id}>
                                      <div className="main-form-section mt-4">
                                        <Row className="justify-content-center">
                                          <Form.Label>
                                            Single Variant
                                          </Form.Label>
                                          <Form.Group>
                                            <div className="image-boxes-main">
                                              <img
                                                className="image-boxes"
                                                src={IMG_URL + value?.image}
                                                alt="Single Variant"
                                              />
                                            </div>
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    </Col>
                                  ))
                                )}
                              </>
                            ) : (
                              <>
                                {data?.product_multiple_mains?.map((variant) =>
                                  variant?.product_multiple_variants?.map(
                                    (value) =>
                                      value?.product_multi_varient_images?.map(
                                        (image) => (
                                          <Col md={4} key={image?.id}>
                                            <div className="main-form-section mt-4">
                                              <Row className="justify-content-center">
                                                <Form.Label>Images</Form.Label>
                                                <Form.Group>
                                                  <div className="image-boxes-main">
                                                    <img
                                                      className="image-boxes"
                                                      src={
                                                        IMG_URL + image?.image
                                                      }
                                                      alt="Multiple Variant"
                                                    />
                                                  </div>
                                                </Form.Group>
                                              </Row>
                                            </div>
                                          </Col>
                                        )
                                      )
                                  )
                                )}
                              </>
                            )}
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default ViewOffCanvance;
